import { ref } from 'vue'
import {
	type PaymentTypeResponce, type PaymentRequest,
	type PaymenLinkResponse, type PayRequest, type PayResponse,
	type FastPayRequestType, type TopUpRequest, type TopUpResponse,
	type GetTopUpSteamResponse, type checkAccountSteamRequest,
	type checkAccountSteamResponse, type promocodeResponce, type countryResponce,
	type paymentTypeMajor,
	type FastPayResponse
} from './types/paymentTypes' // автозамена вскода прикололась кста держу в курсе

export const usePayments = () => {
	const paymentMethods = ref<PaymentTypeResponce[]>([])
	const { $api } = useNuxtApp()
	const getPaymentMethods = async () => {
		try {
			const res = await $api<PaymentTypeResponce[]>('/payment/methods')
			paymentMethods.value = res
			return res
		} catch (error) {
			return null
		}
	}
	const fastPay = async (paymentData: FastPayRequestType) => {
		const res = await $api<FastPayResponse>('/payment/fast-pay', {
			body: paymentData,
			method: 'POST'
		})
		if (res.paymentUrl || res.token || res.hasBeenBalance) {

			return res
		}
		return null
	}

	const replenishBalance = async (paymentData: PaymentRequest) => {
		try {
			const res = await $api<PaymenLinkResponse>('/payment/replenishment-balance', {
				body: paymentData,
				method: 'POST'
			})
			return res.paymentUrl
		} catch (error) {
			return { error }
		}
	}

	const pay = async (paymentData: PayRequest) => {
		try {
			const res = await $api<PayResponse>('/payment/pay', {
				body: paymentData,
				method: 'POST'
			})
			return res
		} catch (error) {
			console.error('Error fetching orders:', error);
			return null;
		}
	}

	const topUp = async (options: TopUpRequest) => {

		return await $api<TopUpResponse>('/payment/top-up', {
			body: options,
			method: 'POST'
		}).then((res) => {
			return res
		}).catch((error) => {
			throw error
		})
	}

	const getTopUpSteam = async () => {
		return await $api<GetTopUpSteamResponse>('/payment/top-up-steam', {
			method: 'GET'
		}).then((res) => {
			return res
		}).catch((error) => {
			throw error
		})
	}

	const checkAccountSteam = async (options: checkAccountSteamRequest) => {
		return await $api<checkAccountSteamResponse>('/payment/check-account-steam', {
			method: 'POST',
			body: options,
		}).then((res) => {
			return res
		}).catch((error) => {
			throw error
		})
	}

	const checkPromocode = async (promocode: string) => {
		try {
			const response = await $api<promocodeResponce>('/payment/check-promocode', {
				method: 'POST',
				body: { promocode: promocode }
			});
			return { success: true, data: response };
		} catch (error: any) {
			return {
				success: false,
				error: error.response?._data || error.message
			};
		}
	}

	const getAvailableCountires = async () => {
		return await $api<{ countries: countryResponce[] }>('payment/available-countries').then((res) => {
			return res
		}).catch((error) => {
			throw error
		})
	}

	const getAvailablePaymentsForCountry = async (code: string) => {
		return await $api<{ payments: paymentTypeMajor[] }>('payments/available-payments-for-country', {
			method: 'GET',
			params: { code: code },
		}).then((res) => {
			return res
		}).catch((error) => {
			throw error
		})
	}

	return {
		paymentMethods,
		getPaymentMethods,
		replenishBalance,
		pay,
		fastPay,
		topUp,
		getTopUpSteam,
		checkAccountSteam,
		checkPromocode
	}
}